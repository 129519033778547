#confirmation-dialog-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 32px;
    width: 400px;

    .confirmation-dialog-button-group {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .message {
        text-align: center;
        font-size: 20px;
    }
}
